<template>
  <div>
    <h2>Справочник ЕГР</h2>
    <search-block>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-popover
            ref="popoverIds"
            placement="right"
            width="200"
            title="Поиск"
            trigger="focus"
            content="Начните вводить УНП"
          >
          </el-popover>
          <el-select
            clearable
            v-popover:popoverIds
            @change="searchEgrById"
            class="searchPole"
            v-model="search.id"
            filterable
            remote
            value-key="egrId"
            reserve-keyword
            placeholder="УНП"
            :remote-method="searchIds"
          >
            <el-option
              v-for="item in egrIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="12">
          <el-popover
            ref="popoverNames"
            placement="right"
            width="200"
            title="Поиск"
            trigger="focus"
            content="Начните вводить наименование"
          >
          </el-popover>
          <el-select
            clearable
            v-popover:popoverNames
            @change="searchEgrById"
            class="searchPole"
            v-model="search.id"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Полное наименование"
            :remote-method="searchNames"
          >
            <el-option
              v-for="item in egrNames"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </search-block>
    <el-table stripe border v-loading="loadData" :data="egrList.content">
      <el-table-column label="УНП" prop="egrId"></el-table-column>
      <el-table-column
        label="Наименование ЮР лица/ФИО ИП"
        prop="fio"
      ></el-table-column>
      <el-table-column
        label="Название компании"
        prop="companyName"
      ></el-table-column>
      <el-table-column label="Полный Адрес" prop="address"></el-table-column>
      <el-table-column label="Состояние" prop="status"></el-table-column>
      <el-table-column
        label="Делопроизводство"
        prop="businessName"
      ></el-table-column>
      <el-table-column label="Контакты" prop="contacts">
        <template slot-scope="scope">
          <p v-html="scope.row.contacts"></p>
        </template>
      </el-table-column>
      <el-table-column
        label="Дата регистрации"
        prop="startDate"
      ></el-table-column>
      <el-table-column
        label="Дата исключения из ЕГР"
        prop="endDate"
      ></el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="egrList.size"
      :current-page.sync="egrList.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="egrList.totalElements"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";

export default {
  name: "egrMain",
  components: { SearchBlock },
  data() {
    return {
      loadData: true,
      search: {
        id: null,
        name: null,
      },
      pageOptions: {
        page: 0,
        size: 20,
      },
    };
  },

  computed: {
    ...mapGetters({
      egrList: "GETEGRLIST",
      egrNames: "GETEGRNAMESLIST",
      egrIds: "GETEGRIDSLIST",
    }),
  },

  methods: {
    searchEgrById() {
      console.log(this.search.id);
      this.$store.dispatch("getEgrObject", this.search.id);
    },

    searchIds(query) {
      this.getEgrIds(query);
    },

    searchNames(query) {
      if (query.length >= 3) {
        this.getEgrNames(query);
      }
    },

    handleSizeChange(val) {
      this.getListEgrObjects({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getListEgrObjects({
        page: val - 1,
        size: this.egrList.size,
      });
    },

    getEgrNames(query) {
      this.$store.dispatch("getEgrNames", { query: query });
    },

    getEgrIds(query) {
      this.$store.dispatch("getEgrIds", { query: query });
    },

    async getListEgrObjects(data) {
      await this.$store.dispatch("getListEgrObjects", data);
    },
  },

  async mounted() {
    await this.getListEgrObjects(this.pageOptions);
    this.loadData = false;
  },
};
</script>

<style scoped></style>
