<template>
  <div>
    <egr-main></egr-main>
  </div>
</template>

<script>
import EgrMain from "@/components/admin/egr/egrMain";
export default {
  name: "egrView",
  components: { EgrMain },
};
</script>

<style scoped></style>
